import { PoolSegment } from "@/types/poolSegment";
import { isInDateRange } from "@/utils/dateTools";
import { APIClient } from "@/utils/httpClient";
import { defineStore } from "pinia";
import { dropPoolStore } from "./dropPoolStore";
import { authStore } from "./authStore";

interface IPoolSegmentStoreState {
	segments: PoolSegment[];
}

export const poolSegmentStore = defineStore("poolSegmentStore", {
	persist: true,
	state: () =>
		({
			segments: [],
		} as IPoolSegmentStoreState),
	getters: {
		getSegments(state: IPoolSegmentStoreState): PoolSegment[] {
			return state.segments;
		},
		getSegmentByID:
			(state: IPoolSegmentStoreState) =>
			(dropPoolID: string): PoolSegment | null => {
				for (let x = 0; x < state.segments.length; x++) {
					if (state.segments[x].poolID == dropPoolID) {
						if (
							isInDateRange(
								new Date().toISOString(),
								state.segments[x].startDate,
								state.segments[x].endDate
							)
						) {
							return state.segments[x];
						}
					}
				}
				return null;
			},
	},
	actions: {
		setItems(items: PoolSegment[]) {
			this.segments = items;
		},
		clearItems() {
			this.segments = [];
		},
		async getPoolSegments() {
			const dropPool = dropPoolStore().activePool;
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/dropPools/${
				dropPool!.id
			}/segments`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.segments = response.data;
					return response.data;
				}
			} catch (error) {
				console.error(error);
			}
		},
		async getCampaignSegments() {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/segments/all`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.segments = response.data;
					return response.data;
				}
			} catch (error) {
				console.error(error);
			}
		},
	},
});

<template>
	<div class="custom-page" v-if="permissionsPage">
		<ContainerObject :containerObject="permissionsPage.layout" />
		<LoadingComponent v-if="loading" />
	</div>
</template>

<script lang="ts">
import ContainerObject from "@/components/layout/ContainerObject.vue";
import LoadingComponent from "@/components/utils/LoadingComponent.vue";
import { customPageStore } from "@/store/pinia_modules/customPageStore";
import { navigationStore } from "@/store/pinia_modules/navigationStore";
import { Action, CustomPage } from "@/types/customPage";
import { defineComponent } from "vue";

export default defineComponent({
	// components: { ContainerObject, LoadingComponent },
	data() {
		return {
			loading: false,
		};
	},
	mounted() {
		setTimeout(() => {
			this.listPermissions();
		}, 100);
	},
	methods: {
		listPermissions() {
			const permissionContainer = document.getElementById("permissions");
			if (permissionContainer) {
				permissionContainer.innerHTML = "";
				permissionContainer.innerHTML = `<div>${this.makeList()}</div>`;

				console.log(this.deniedPermissions);
			}
		},
		makeList(): string {
			let list = "";
			
			const permissionMap: { [key: string]: string } = {
				"Geolocation": "Enable Device Location And Browser Location Permissions",
			};

			for (const permission of this.deniedPermissions) {
				const displayPermission = permissionMap[permission] ?? permission;
				list = `<div data-original="${permission}">${displayPermission}</div>`;
			}

			return list;
		},
	},
	computed: {
		permissionsPage(): CustomPage | undefined {
			return customPageStore().getPageByURL("permissions");
		},
		action(): Action {
			return navigationStore().getAction;
		},
		deniedPermissions(): string[] {
			return navigationStore().getDeniedPermissions;
		},
	},
});
</script>

<style scoped lang="scss">
.custom-page {
	position: relative;
	width: 100%;
	height: 100%;
}
</style>

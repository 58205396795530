<template>
	<div v-if="show" class="backround">
		<div class="modal">
			<div class="close" @click="close()">x</div>
			<div class="text">You have found a profit beer</div>
			<div class="footer" @click="claim()">GRAB CAN</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		show: Boolean,
	},
	methods: {
		close() {
			this.$emit("close");
		},
		claim() {
			this.$emit("claim");
		},
	},
});
</script>

<style scoped lang="scss">
.backround {
	position: fixed;
	z-index: 1000000;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	.modal {
		position: absolute;
		display: flex;
		flex-direction: column;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50%;
		background-color: white;
		padding: 20px;
		border-radius: 10px;
		.close {
			position: absolute;
			top: 8px;
			right: 8px;
			width: 30px;
			height: 30px;
			border-style: solid;
			border-width: 1px;
			border-radius: 30px;
			text-align: center;
			font-size: 20px;
		}
		.text {
			text-align: center;
			margin-bottom: 15px;
		}
		.footer {
			background-color: #000;
			color: #fff;
			text-align: center;
			line-height: 38px;
			height: 40px;
			border-radius: 8px;
		}
	}
}
</style>

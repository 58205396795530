<template>
	<div class="backround">
		<div class="loading">
			<font-awesome-icon class="spin" :icon="['fas', 'circle-notch']" />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped lang="scss">
.backround {
	position: fixed;
	z-index: 10000;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	.loading {
		color: #fff;
		text-align: center;
	}
}
.spin {
	font-size: 30px;
	animation: rotation 1s infinite linear;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>

import { CustomPage } from "@/types/customPage";
import { APIClient } from "@/utils/httpClient";
import { defineStore } from "pinia";
import { authStore } from "./authStore";

interface ICustomPageStoreState {
	items: Array<CustomPage>;
}

export const customPageStore = defineStore("customPageStore", {
	persist: true,
	state: () =>
		({
			items: [],
		} as ICustomPageStoreState),
	getters: {
		getAllPages(state: ICustomPageStoreState) {
			return state.items;
		},
		getPageByURL: (state: ICustomPageStoreState) => (url: string) => {
			return state.items.find((e: CustomPage) => e.url == url);
		},
	},
	actions: {
		saveItems(items: Array<CustomPage>) {
			this.items = items;
		},
		clearItems(): void {
			this.items = [];
		},
		async getCustomPages() {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/customPages`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.items = response.data;
				}
			} catch (error) {
				console.error(error);
			}
		},
	},
});

import { APIClient } from "@/utils/httpClient";



export const incrementActionPoints = async (
    campaignID: string,
    userID: string,
    actionPoints: number
): Promise<void> => {
    try {
        const endpoint = `/campaigns/${campaignID}/users/${userID}/action-points/increment`;
        const response = await APIClient.post(endpoint, { points: actionPoints });
        if (response?.success) {
            return;
        }
        throw new Error("Failed to increment action points");
    } catch (error) {
        console.error(error);
    }
};


<template>
	<div class="error-page">
		<div class="error-content">
			<div>Oops something went wrong!</div>
			<div>{{ errorMessage }}</div>
			<button style="color: #000" @click="tryAgain()" class="try-again">
				Click here to reload
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { authStore } from "@/store/pinia_modules/authStore";
import { defineComponent } from "vue";

export default defineComponent({
	data() {
		return {
			errorMessage: "",
		};
	},
	mounted() {
		this.getQueryParams();
	},
	methods: {
		getQueryParams() {
			const params = new URL(document.location.toString()).searchParams;
			params.forEach((v, k) => {
				params.set(k, encodeURI(v));
			});
			if (params.get("shortMessage")) {
				this.errorMessage = decodeURI(params.get("shortMessage") as string);
			}
		},
		tryAgain() {
			const campaignID = this.campaignID;
			localStorage.clear();
			window.location.href = `${window.location.origin}/${campaignID}`;
		},
	},
	computed: {
		campaignID(): string | null {
			return authStore().getCampaignID;
		},
	},
	components: {},
});
</script>

<style scoped lang="scss">
.error-page {
	background-color: #000;
	color: #fff;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.error-content {
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 10px;
}
.logo {
	width: 100px;
	height: 100px;
	object-fit: contain;
}
.try-again {
	height: 40px;
	border: none;
	border-radius: 20px;
	width: 100%;
}
</style>

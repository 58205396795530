<template>
	<input
		autocomplete="off"
		:id="element.attributes.id"
		:style="element.styles"
		:placeholder="element.attributes.placeholder"
		@keyup.enter="doKeyUpAction(element.action)"
	/>
</template>

<script lang="ts">
import { navigationStore } from "@/store/pinia_modules/navigationStore";
import { Action, Container } from "@/types/customPage";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
	methods: {
		doAction(action: Action) {
			navigationStore().emitAction(action);
		},
		doKeyUpAction(action: Action) {
			if (
				action &&
				action.value &&
				(action.value == "signin-request-code" ||
					action.value == "signup" ||
					action.value == "submit-pin")
			) {
				navigationStore().emitAction(action);
			}
		},
	},
});
</script>

<style scoped lang="scss"></style>

import moment from "moment";

export function isInDateRange(
	now: string,
	start: string,
	end: string
): boolean {
	const date = moment(now);
	const startDate = moment(start);
	const endDate = moment(end);
	return date.isBetween(startDate, endDate);
}

export function createDatesBetween(
	startDate: string,
	endDate: string,
	startTime: string,
	endTime: string,
	increment: string
): [string, string][] {
	const dates: [string, string][] = [];
	let date = moment(startDate);
	const end = moment(endDate);
	if (increment == "single") {
		dates.push([
			date.format(`YYYY-MM-DDT${startTime}:ss.SSSS[Z]`),
			end.format(`YYYY-MM-DDT${endTime}:ss.SSSS[Z]`),
		]);
	} else if (increment == "daily") {
		while (date.isBefore(end)) {
			dates.push([
				date.format(`YYYY-MM-DDT${startTime}:ss.SSSS[Z]`),
				date.format(`YYYY-MM-DDT${endTime}:ss.SSSS[Z]`),
			]);
			date = date.add(1, "day");
		}
	}

	return dates;
}

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ViewController from "../views/ViewController.vue";
import Register from "../views/Register.vue";
import SignIn from "../views/Signin.vue";
import EnterPin from "../views/EnterPin.vue";
import Error from "../views/Error.vue";
import Scanner from "../views/Scanner.vue";
import DeviceDetection from "../views/DeviceDetection.vue";
import Permissions from "../views/Permissions.vue";

import Wallet from "../views/Wallet.vue";
import Map from "../views/Map.vue";
import AR from "../views/AR.vue";
import Game from "../views/Game.vue";
import { nextTick } from "vue";
import CampaignPaused from "@/views/CampaignPaused.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/error",
		name: "Error",
		component: Error,
	},
	{
		path: "/:campaign",
		name: "Register",
		component: Register,
	},
	{
		path: "/:campaign/sign-in",
		name: "SignIn",
		component: SignIn,
	},
	{
		path: "/:campaign/not-for-desktop",
		name: "DeviceDetection",
		component: DeviceDetection,
	},
	{
		path: "/:campaign/campaign-paused",
		name: "CampaignPaused",
		component: CampaignPaused,
	},
	{
		path: "/:campaign/enter-pin",
		name: "Enter Pin",
		component: EnterPin,
	},
	{
		path: "/:campaign/vault",
		name: "Wallet",
		component: Wallet,
	},
	{
		path: "/:campaign/map",
		name: "Map",
		component: Map,
	},
	{
		path: "/:campaign/ar-viewer",
		name: "AR",
		component: AR,
	},
	{
		path: "/:campaign/game",
		name: "Game",
		component: Game,
	},
	{
		path: "/:campaign/scanner",
		name: "Scanner",
		component: Scanner,
	},
	{
		path: "/:campaign/permissions",
		name: "Permissions",
		component: Permissions,
	},
	{
		path: "/:campaign/:page",
		name: "Custom",
		component: ViewController,
	},
];

// history: createWebHistory(import.meta.env.BASE_URL),

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
});
router.afterEach(() => {
	if (!isMobile() && router.currentRoute.value.name != "DeviceDetection") {
		router.push({ name: "DeviceDetection" });
	}
	return true;
});

function isMobile(): boolean {
	let isMobile =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
			? true
			: false;
	const isDevMode = process.env.NODE_ENV == "development" ? true : false;
	if (isDevMode) {
		isMobile = true;
	}

	return isMobile;
}
router.beforeEach(async () => {
	nextTick(() => {
		const title = document.querySelector("#site-title");
		if (title) {
			const text = title.textContent;
			if (text) {
				document.title = text;
			}
		}
	});
});

export default router;

import { authStore } from "@/store/pinia_modules/authStore";
import axios from "axios";
const URL = import.meta.env.VITE_APP_BACKEND_URL as string;

enum ErrorLevel {
	INFO = "info",
	ERROR = "error",
}

class ErrorLog {
	public ERROR(message: string) {
		this.send(ErrorLevel.ERROR, message);
	}
	public INFO(message: string) {
		this.send(ErrorLevel.INFO, message);
	}
	private async send(level: ErrorLevel, message: string) {
		const logging = authStore().logging;
		if (logging) {
			const resp: any = await axios.post(`${URL}/logging`, {
				level: level,
				message: message,
			});
			if (!resp.data.data.logging || resp["status"] !== 200) {
				authStore().saveStopLogging();
			}
		}
	}
}
export const ErrorLogger = new ErrorLog();

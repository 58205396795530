<template>
	<div class="custom-page">
		<ContainerObject v-if="pageByURL" :containerObject="pageByURL.layout" />
	</div>
</template>

<script lang="ts">
import ContainerObject from "@/components/layout/ContainerObject.vue";
import { prizeStore } from "@/store/pinia_modules/prizeStore";
import { dropPoolStore } from "@/store/pinia_modules/dropPoolStore";
import { CustomPage } from "@/types/customPage";
import { defineComponent } from "vue";
import { authStore } from "@/store/pinia_modules/authStore";
import { customPageStore } from "@/store/pinia_modules/customPageStore";

export default defineComponent({
	data() {
		return {
			view: "wallet",
		};
	},

	mounted() {
		console.log(this.$route);
		prizeStore().getAllPrizesWon();
		this.view = this.$route.path.split("/")[2];
	},
	watch: {
		$route(to: any) {
			this.view = to.path.split("/")[2];
		},

		async permissionGranted(to: boolean) {
			if (to) {
				const campaignID = authStore().getCampaignID;
				try {
					await this.$router.push({ path: `/${campaignID}/wallet` });
				} catch (error) {
					console.error(error);
				}
			}
		},
	},
	computed: {
		permissionGranted() {
			return dropPoolStore().getPermissionGranted;
		},
		pageByURL(): CustomPage | undefined {
			return customPageStore().getPageByURL(this.view);
		},
	},
	// components: { ContainerObject },
});
</script>

<style scoped lang="scss">
.custom-page {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
</style>

<template>
	<div>
		<!-- <a
			v-if="element.action.type == 'go-to-url'"
			:href="'//' + getURLParams('url')"
			:style="element.styles"
			:target="getURLParams('target')"
			>{{ element.text }}</a
		> -->
		<p
			:id="element.attributes.id"
			:class="renderClasses(element.attributes)"
			@click="doAction(element.action)"
			:style="element.styles"
			v-html="element.text"
		></p>
	</div>
</template>

<script lang="ts">
import { Action, Attributes, Container } from "@/types/customPage";
import { defineComponent, PropType } from "vue";
import { navigationStore } from "@/store/pinia_modules/navigationStore";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
	methods: {
		gotoPage(page: string) {
			this.$router
				.push({
					path: page,
				})
				.catch((err) => {
					console.log(err);
				});
		},
		renderClasses(attributes: Attributes) {
			if (attributes.classes && attributes.classes.length > 0) {
				return attributes.classes.join(" ");
			}
			return "";
		},
		doAction(action: Action) {
			navigationStore().emitAction(action);
		},
	},
});
</script>

<style scoped lang="scss"></style>

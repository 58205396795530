<template>
	<div class="custom-page">
		<ContainerObject
			v-if="deviceDetectionPage"
			:containerObject="deviceDetectionPage.layout"
		/>
		<!-- <LoadingComponent v-if="loading" /> -->
	</div>
</template>

<script lang="ts">
import ContainerObject from "@/components/layout/ContainerObject.vue";
import { Action, CustomPage } from "@/types/customPage";
// import LoadingComponent from "@/components/utils/LoadingComponent.vue";
import { defineComponent } from "vue";
import { customPageStore } from "@/store/pinia_modules/customPageStore";
import { navigationStore } from "@/store/pinia_modules/navigationStore";

export default defineComponent({
	// components: { ContainerObject, LoadingComponent },
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		deviceDetectionPage(): CustomPage | undefined {
			return customPageStore().getPageByURL("not-for-desktop");
		},
		action(): Action {
			return navigationStore().getAction;
		},
	},
});
</script>

<style scoped lang="scss">
.custom-page {
	position: relative;
	width: 100%;
	height: 100%;
}
</style>

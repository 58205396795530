<template>
	<textarea
		id="html-text-input"
		@blur="updateElement()"
		v-if="elementRef.active && editable"
		v-model="elementRef.text"
		style="color:#000"
	/>
	<div :style="elementRef.styles" v-html="element.text" v-else></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object,
			required: true
		},
		editable: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	beforeMount() {
		this.elementRef = this.element;
		this.elementRef.containers = [];
	},
	data() {
		return {
			inputRef: document.querySelector(
				"#html-text-input"
			) as HTMLInputElement,
			elementRef: this.element,
			edit: false
		};
	},
	emits: ["update"],
	methods: {
		updateElement() {
			this.elementRef.active = false;
			this.$emit("update", this.elementRef);
		}
	}
});
</script>

<style scoped lang="scss">
.btn-text-input {
	border-style: none;
	background: none;
	padding: 0px;
	outline: none;
	width: auto;
}
.btn-text {
	background: none;
	padding: 0px;
	width: 100%;
}
</style>

<template>
	<div
		v-if="containerObject"
		@click="doAction(containerObject.action)"
		:id="containerObject.attributes.id"
		:style="containerObject.styles"
		:class="{ container: true }"
	>
		<div
			:style="`height:${
				element.type == 'Map' || element.type == 'AR' ? '100%' : 'auto'
			};`"
			v-for="(element, index) in containerObject.elements"
			:key="element._id + index"
		>
			<ButtonComponent v-if="element.type == 'Button'" :element="element" />
			<IconComponent v-if="element.type == 'Icon'" :element="element" />
			<CheckboxComponent v-if="element.type == 'Checkbox'" :element="element" />

			<HeaderTextComponent v-if="element.type == 'Header'" :element="element" />
			<HTMLTextComponent v-if="element.type == 'HTMLText'" :element="element" />
			<ParagraphComponent
				v-if="element.type == 'Paragraph'"
				:element="element"
			/>
			<InputTextComponent v-if="element.type == 'Input'" :element="element" />
			<YearSelectComponent
				v-if="element.type == 'YearSelect'"
				:element="element"
			/>
			<MonthSelectComponent
				v-if="element.type == 'MonthSelect'"
				:element="element"
			/>
			<DaySelectComponent
				v-if="element.type == 'DaySelect'"
				:element="element"
			/>
			<InputTextAreaComponent
				v-if="element.type == 'TextArea'"
				:element="element"
			/>
			<ARComponent v-if="element.type == 'AR'" :element="element" />
			<MapComponent v-if="element.type == 'Map'" :element="element" />
			<VideoComponent
				v-if="element.type == 'Video'"
				@event="customEvent($event)"
				:element="element"
			/>
			<IFrameComponent v-if="element.type == 'IFrame'" :element="element" />
			<ScrollerComponent
				v-if="element.type == 'Scroller'"
				:element="element"
				style="height: 100%"
			/>
		</div>
		<template v-if="containerObject.containers">
			<ContainerObject
				v-for="(container, index) in containerObject.containers"
				:key="container._id + index"
				:containerObject="container"
			/>
		</template>
	</div>
</template>

<script lang="ts">
import ButtonComponent from "@/components/layout/ButtonComponent.vue";
import CheckboxComponent from "@/components/layout/CheckboxComponent.vue";
import IconComponent from "@/components/layout/IconComponent.vue";
import HeaderTextComponent from "@/components/layout/HeaderTextComponent.vue";
import HTMLTextComponent from "@/components/layout/HTMLTextComponent.vue";
import ParagraphComponent from "@/components/layout/ParagraphComponent.vue";
import InputTextComponent from "@/components/layout/InputTextComponent.vue";
import InputTextAreaComponent from "@/components/layout/InputTextAreaComponent.vue";
import VideoComponent from "@/components/layout/VideoComponent.vue";
import ARComponent from "@/components/layout/ARComponent.vue";
import MapComponent from "@/components/layout/MapComponent.vue";
import IFrameComponent from "@/components/layout/IFrameComponent.vue";
import { Action, Container } from "@/types/customPage";
import ScrollerComponent from "@/components/layout/ScrollerComponent.vue";
import { defineComponent, PropType } from "vue";
import YearSelectComponent from "./YearSelectComponent.vue";
import MonthSelectComponent from "./MonthSelectComponent.vue";
import DaySelectComponent from "./DaySelectComponent.vue";
import { navigationStore } from "@/store/pinia_modules/navigationStore";

export default defineComponent({
	props: {
		containerObject: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
	components: {
		ButtonComponent,
		IconComponent,
		HeaderTextComponent,
		HTMLTextComponent,
		ParagraphComponent,
		InputTextComponent,
		VideoComponent,
		IFrameComponent,
		ARComponent,
		MapComponent,
		CheckboxComponent,
		InputTextAreaComponent,
		ScrollerComponent,
		YearSelectComponent,
		MonthSelectComponent,
		DaySelectComponent,
	},
	methods: {
		// gotoPage(page: string) {
		// 	this.$router.push(`/${page}`);
		// }
		customEvent($event: any) {
			this.$emit("event", $event);
		},
		doAction(action: Action) {
			navigationStore().emitAction(action);
		},
	},
});
</script>

<style scoped lang="scss">
.container {
	position: relative;
	// height: 100%;
}
</style>

<template>
	<div class="custom-page">
		<GameCodeComponent />
	</div>
</template>

<script lang="ts">
import GameCodeComponentVue from "@/components/layout/GameCodeComponent.vue";
import { CustomPage } from "@/types/customPage";
import { defineComponent } from "vue";
import GameCodeComponent from "@/components/layout/GameCodeComponent.vue";
import { prizeStore } from "@/store/pinia_modules/prizeStore";
import { customPageStore } from "@/store/pinia_modules/customPageStore";

export default defineComponent({
	components: { GameCodeComponentVue, GameCodeComponent },
	mounted(): void {
		prizeStore().getAllPrizesWon();
	},
	computed: {
		ARPage(): CustomPage | undefined {
			return customPageStore().getPageByURL("ar-viewer");
		},
	},
});
</script>

<style scoped lang="scss">
.custom-page {
	position: relative;
	width: 100%;
	height: 100%;
}
</style>

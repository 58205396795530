<template>
	<div class="qr-code-scanner">
		<p v-if="loading" class="loading">Loading scanner...</p>
		<qrcode-stream
			v-if="cameraPermissionGranted"
			@decode="onDecode"
			@init="onInit"
		/>
		<div class="close-scanner" @click="goToVault">
			<font-awesome-icon style="color: #fff" :icon="['fas', 'times']" />
		</div>
	</div>
</template>

<script lang="ts">
import { vItemStore } from "@/store/pinia_modules/vItemStore";
import { navigationStore } from "@/store/pinia_modules/navigationStore";

import { defineComponent } from "vue";
import { QrcodeStream } from "vue3-qrcode-reader";

export default defineComponent({
	components: { QrcodeStream },
	data() {
		return {
			result: "",
			error: "",
			loading: false,
			cameraPermissionGranted: false,
		};
	},
	methods: {
		async onDecode(merchantWalletID: string) {
			const vItemState = vItemStore();
			const navigationState = navigationStore();
			this.result = merchantWalletID;
			const item = vItemState.getSelectedItem;
			const response = await vItemState.redeemItem(
				`${item!.id}_${merchantWalletID}`
			);
			if (response && response.success) {
				navigationState.emitAction({
					type: "open-popup",
					value: "redeem-qr-success",
				});
			} else {
				if (response && response.data.status == "MERCHANT-WALLET-ERROR") {
					navigationState.emitAction({
						type: "open-popup",
						value: "incorrect-qr-code",
					});
				}
			}
		},
		async onInit(promise: any) {
			try {
				this.loading = true;
				await promise;
			} catch (error: any) {
				if (error.name === "NotAllowedError") {
					this.error = "ERROR: you need to grant camera access permission";
				} else if (error.name === "NotFoundError") {
					this.error = "ERROR: no camera on this device";
				} else if (error.name === "NotSupportedError") {
					this.error = "ERROR: secure context required (HTTPS, localhost)";
				} else if (error.name === "NotReadableError") {
					this.error = "ERROR: is the camera already in use?";
				} else if (error.name === "OverconstrainedError") {
					this.error = "ERROR: installed cameras are not suitable";
				} else if (error.name === "StreamApiNotSupportedError") {
					this.error = "ERROR: Stream API is not supported in this browser";
				} else if (error.name === "InsecureContextError") {
					this.error =
						"ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
				} else {
					console.error(error);
					this.error = `ERROR: Camera error (${error.name})`;
				}
				this.loading = false;
			}
			this.loading = false;
		},
		goToVault() {
			const navigationState = navigationStore();
			navigationState.emitAction({
				type: "go-to-page",
				value: "vault",
			});
		},
		async checkCameraPermission() {
			try {
				this.loading = true;
				try {
					const stream = await navigator.mediaDevices.getUserMedia({ video: true });
					stream.getTracks().forEach(track => track.stop());
					this.cameraPermissionGranted = true;
				} catch (err: any) {
					if (err.name === 'NotAllowedError') {
						alert("Camera access is required to scan QR codes.\n\nPlease enable camera access in your browser settings.");
						this.handlePermissionDenied();
					}
				}
			} catch (err) {
				console.error(err);
				this.error = "ERROR: Unable to check camera permissions.";
			} finally {
				this.loading = false;
			}
		},
		handlePermissionDenied() {
			const navigationState = navigationStore();
			navigationState.emitAction({
				type: "go-to-page",
				value: "vault"
			});
		},
	},
	mounted() {
		this.checkCameraPermission();
	}
});
</script>

<style scoped lang="scss">
.qr-code-scanner {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: #000;
	.loading {
		position: absolute;
		left: 50%;
		top: 50%;
		color: #fff;
		transform: translate(-50%, -50%);
	}
}
.close-scanner {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 15px;
	left: 50%;
	width: 30px;
	height: 30px;
	border-radius: 100px;
	margin-left: -15px;
	background-color: rgba(0, 0, 0, 0.5);
}
</style>

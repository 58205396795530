<template>
	<textarea
		autocomplete="off"
		:id="element.attributes.id"
		:style="element.styles"
		:placeholder="element.attributes.placeholder"
	></textarea>
</template>

<script lang="ts">
import { Action, Container } from "@/types/customPage";
import { defineComponent, PropType } from "vue";
import { navigationStore } from "@/store/pinia_modules/navigationStore";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
	methods: {
		doAction(action: Action) {
			navigationStore().emitAction(action);
		},
	},
});
</script>

<style scoped lang="scss"></style>

<template>
	<div
		:id="element.attributes.id"
		:class="renderClasses(element.attributes)"
		@click="doAction(element.action)"
		:style="element.styles"
		v-html="element.text"
	></div>
</template>

<script lang="ts">
import { Action, Attributes, Container } from "@/types/customPage";
import { navigationStore } from "@/store/pinia_modules/navigationStore";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
	methods: {
		gotoPage(page: string) {
			this.$router
				.push({
					path: page,
				})
				.catch((err) => {
					console.log(err);
				});
		},
		renderClasses(attributes: Attributes) {
			if (attributes.classes && attributes.classes.length > 0) {
				return attributes.classes.join(" ");
			}
			return "";
		},
		doAction(action: Action) {
			navigationStore().emitAction(action);
		},
	},
});
</script>

<style scoped lang="scss"></style>

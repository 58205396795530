import { Prize } from "@/types/prize";
import { APIClient } from "@/utils/httpClient";
import { defineStore } from "pinia";
import { authStore } from "./authStore";

interface IPrizeStoreState {
	items: Prize[];
	isWinning: boolean;
	clickToPlay: boolean;
	prizesWon: number;
}

export const prizeStore = defineStore("prizeStore", {
	persist: true,
	state: () =>
		({
			items: [],
			isWinning: true,
			clickToPlay: false,
			prizesWon: 0,
		} as IPrizeStoreState),
	getters: {
		getAllPrizes(state: IPrizeStoreState) {
			return state.items;
		},
		getPrizeByID:
			(state: IPrizeStoreState) =>
			(id: string): Prize | undefined => {
				return state.items.find((e: any) => e.id == id);
			},
		getIsWinning(state: IPrizeStoreState) {
			return state.isWinning;
		},
		getClickToPlay(state: IPrizeStoreState) {
			return state.clickToPlay;
		},
	},
	actions: {
		saveItems(items: any) {
			this.items = items;
		},
		saveIsWinning(isWinning: boolean) {
			this.isWinning = isWinning;
		},
		saveClickToPlay(clickToPlay: boolean) {
			this.clickToPlay = clickToPlay;
		},
		savePrizesWon(count: number) {
			this.prizesWon = count;
		},
		async getPrizes() {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/prizes`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.items = response.data;
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		async getAllPrizesWon() {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/prizes/used`;
			setTimeout(async () => {
				const elements = document.getElementsByClassName("prizes-won");

				if (elements && elements.length > 0) {
					for (let i = 0; i < elements.length; i++) {
						elements[i].innerHTML = this.prizesWon.toString();
					}
					try {
						const response = await APIClient.get(endpoint);
						if (response.success) {
							if (elements && elements.length > 0) {
								for (let i = 0; i < elements.length; i++) {
									elements[i].innerHTML = response.data;
								}
							}
							this.savePrizesWon(response.data);
						}
					} catch (error: any) {
						console.error(error);
					}
				}
			}, 100);
		},
	},
});

<template>
	<font-awesome-icon
		:style="element.styles"
		:icon="['fas', `${element.attributes.icon}`]"
	/>
</template>

<script lang="ts">
import { Container } from "@/types/customPage";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
});
</script>

<style scoped lang="scss"></style>

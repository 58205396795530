<template>
	<div class="">
		<label
			class="checkboxHolder"
			:style="element.styles"
			:for="element.attributes.id"
			><input
				class="checkbox"
				type="checkbox"
				:id="element.attributes.id"
				:name="element.attributes.id"
				:checked="element.attributes.checked"
			/>{{ element.attributes.label }}</label
		>
	</div>
</template>

<script lang="ts">
import { Container } from "@/types/customPage";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
});
</script>

<style scoped lang="scss">
.checkboxHolder {
	display: flex;
	align-items: center;
	gap: 5px;
}
.checkbox {
	flex: none;
}
.checkbox-label {
	width: 100%;
}
</style>

<template>
	<iframe
		:style="element.styles"
		:src="element.attributes.src"
		:width="element.attributes.width"
		:height="element.attributes.height"
	></iframe>
</template>

<script lang="ts">
import { navigationStore } from "@/store/pinia_modules/navigationStore";
import { Action, Container } from "@/types/customPage";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
	methods: {
		doAction(action: Action) {
			navigationStore().emitAction(action);
		},
	},
});
</script>

<style scoped lang="scss"></style>

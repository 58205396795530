import { Category } from "@/types/category";
import { APIClient } from "@/utils/httpClient";
import router from "@/router";
import { defineStore } from "pinia";
import { authStore } from "./authStore";

interface ICategoryStoreState {
	categories: Array<Category>;
}

export const categoryStore = defineStore("categoryStore", {
	persist: true,
	state: () =>
		({
			categories: [],
		} as ICategoryStoreState),
	getters: {
		getItems(state: ICategoryStoreState) {
			return state.categories;
		},
		getItemByID:
			(state: ICategoryStoreState) =>
			(id: string): Category | undefined => {
				return state.categories.find((e: Category) => e.id == id);
			},
	},
	actions: {
		addItems(items: Category[]): void {
			this.categories = items;
		},
		async getItemCategories() {
			const authState = authStore();
			const campaignID = authState.campaignID;
			const endpoint = `/campaigns/${campaignID}/categories`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.categories = response.data;
				} else {
					if (
						response.data &&
						response.data.status &&
						response.data.status == "CAMPAIGN-ENDED"
					) {
						router.replace(`/${campaignID}/campaign-over`);
					}
				}
			} catch (error) {
				console.error(error);
			}
		},
	},
});

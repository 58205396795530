import { CustomComponent } from "@/types/customComponent";
import { APIClient } from "@/utils/httpClient";
import { defineStore } from "pinia";
import { authStore } from "./authStore";

interface ICustomComponentStoreState {
	items: Array<CustomComponent>;
}

export const customComponentStore = defineStore("customComponentStore", {
	persist: true,
	state: () =>
		({
			items: [],
		} as ICustomComponentStoreState),
	getters: {
		getAllCustomComponents(state: ICustomComponentStoreState) {
			return state.items;
		},
		getCustomComponentByName:
			(state: ICustomComponentStoreState) => (componentName: string) => {
				return state.items.find((e) => e.name == componentName);
			},
	},
	actions: {
		saveItems(items: any) {
			this.items = items;
		},
		async getCustomComponents() {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/customComponents`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.items = response.data;
				}
			} catch (error: any) {
				console.error(error);
			}
		},
	},
});

<template>
	<div v-if="ARPage" class="custom-page">
		<ContainerObject :containerObject="ARPage.layout" />
	</div>
</template>

<script lang="ts">
import ContainerObject from "@/components/layout/ContainerObject.vue";
import { customPageStore } from "@/store/pinia_modules/customPageStore";
import { prizeStore } from "@/store/pinia_modules/prizeStore";
import { CustomPage } from "@/types/customPage";
import { defineComponent } from "vue";

export default defineComponent({
	// components: { ContainerObject },
	mounted(): void {
		prizeStore().getAllPrizesWon();
	},
	computed: {
		ARPage(): CustomPage | undefined {
			return customPageStore().getPageByURL("ar-viewer");
		},
	},
});
</script>

<style scoped lang="scss">
.custom-page {
	position: relative;
	width: 100%;
	height: 100%;
}
</style>

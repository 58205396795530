<template>
	<div class="custom-page">
		<ContainerObject v-if="signinPage" :containerObject="signinPage.layout" />
		<LoadingComponent v-if="loading" />
	</div>
</template>

<script lang="ts">
import ContainerObject from "@/components/layout/ContainerObject.vue";
import { CustomPage } from "@/types/customPage";
import LoadingComponent from "@/components/utils/LoadingComponent.vue";
import { defineComponent } from "vue";
import { customPageStore } from "@/store/pinia_modules/customPageStore";

export default defineComponent({
	// components: { ContainerObject, LoadingComponent },
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		signinPage(): CustomPage | undefined {
			return customPageStore().getPageByURL("sign-in");
		},
	},
});
</script>

<style scoped lang="scss">
.custom-page {
	position: relative;
	width: 100%;
	height: 100%;
}
</style>

<template>
	<select
		:id="element.attributes.id"
		:style="element.styles"
		:placeholder="element.attributes.placeholder"
	>
		<option :value="null">Year</option>
		<option v-for="year in years" :key="year" :value="year">
			{{ year }}
		</option>
	</select>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			years: [] as string[],
		};
	},
	mounted() {
		const now = new Date().getFullYear();
		const then = now - 100;
		for (let i = now; i >= then; i--) {
			this.years.push(i.toString());
		}
	},
});
</script>

<style scoped lang="scss"></style>

import { Container } from "@/types/customPage";

export const emptyContainerWithMessage = (
	title: string,
	message: string
): Container => ({
	_id: "empty-container",
	type: "container",
	active: true,
	action: {
		type: "none",
		value: "",
	},
	attributes: {},
	elements: [
		{
			elements: [],
			name: "Header",
			active: false,
			action: {
				type: null,
				value: null,
			},
			styles: {
				color: "#ffffffff",
				fontSize: "23px",
			},
			attributes: {},
			containers: [],
			_id: "3d62f41c-d751-4a3b-beee-eaa11f0f8753",
			text: title,
			type: "Header",
		},
		{
			elements: [
				{
					elements: [],
					name: "Paragraph",
					active: false,
					action: {
						type: null,
						value: null,
					},
					styles: {
						color: "#000",
						fontSize: "12px",
					},
					attributes: {
						text: "Paragraph",
					},
					containers: [],
					_id: "8fafda72-7346-4c07-9719-0d235390b634",
					text: "Paragraph",
					type: "Paragraph",
				},
			],
			name: "Paragraph",
			active: false,
			action: {
				type: null,
				value: null,
			},
			styles: {
				color: "#ffffffff",
				fontSize: "12px",
			},
			attributes: {
				text: "Paragraph",
			},
			containers: [],
			_id: "dfa1f83f-33fb-45e8-bdae-16240306df78",
			text: message,
			type: "Paragraph",
		},
	],
	styles: {
		backgroundColor: "#000000ff",
		alignItems: "center",
		textAlign: "left",
		flexDirection: "column",
		display: "flex",
		fontStyle: "normal",
		justifyContent: "center",
		borderRadius: "20px",
		flexWrap: "nowrap",
		width: "150px",
		position: "relative",
		borderStyle: "none",
		backgroundRepeat: "no-repeat",
		height: "150px",
	},
});

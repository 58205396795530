<template>
	<div class="custom-page" v-if="walletPage">
		<ContainerObject :containerObject="walletPage.layout" />
		<LoadingComponent v-if="loading" />
	</div>
</template>

<script lang="ts">
import ContainerObject from "@/components/layout/ContainerObject.vue";
import LoadingComponent from "@/components/utils/LoadingComponent.vue";
// import { categoryStore } from "@/store/pinia_modules/categoryStore";
import { customPageStore } from "@/store/pinia_modules/customPageStore";
// import { dropPoolStore } from "@/store/pinia_modules/dropPoolStore";
import { prizeStore } from "@/store/pinia_modules/prizeStore";
import { vItemStore } from "@/store/pinia_modules/vItemStore";
import { CustomPage } from "@/types/customPage";
import { defineComponent } from "vue";

export default defineComponent({
	components: { LoadingComponent },
	data() {
		return {
			loading: false,
		};
	},
	mounted(): void {
		this.fetchObjects();
	},
	methods: {
		async fetchObjects(): Promise<void> {
			this.loading = true;
			const prizeState = prizeStore();
			// const categoryState = categoryStore();
			// const dropPoolState = dropPoolStore();
			const itemsState = vItemStore();

			prizeState.getAllPrizesWon();
			// await categoryState.getItemCategories();
			// await prizeState.getPrizes();
			// await dropPoolState.getDropPools();
			await itemsState.getItems();
			this.loading = false;
		},
	},
	computed: {
		walletPage(): CustomPage | undefined {
			return customPageStore().getPageByURL("vault");
		},
	},
});
</script>

<style scoped lang="scss">
.custom-page {
	position: relative;
	width: 100%;
	height: 100%;
}
</style>

import { VItem } from "@/types/vItem";
import { APIClient } from "@/utils/httpClient";
import router from "@/router";
import { defineStore } from "pinia";
import { categoryStore } from "./categoryStore";
import { authStore } from "./authStore";
import { prizeStore } from "./prizeStore";
import { navigationStore } from "./navigationStore";
import axios from "axios";

interface IVitemItemStoreState {
	items: VItem[];
	selectedItemIndex: VItem | null;
	selectedItemID: string | null;
}

export const vItemStore = defineStore("vItemStore", {
	persist: true,
	state: () =>
		({
			items: [],
			selectedItemIndex: null,
			selectedItemID: "",
		} as IVitemItemStoreState),
	getters: {
		getDroppedItems(state: IVitemItemStoreState): VItem[] {
			let items = state.items.filter((e: VItem) => e.itemStatus == "dropped");
			items = items.map((e: VItem) => {
				const category = categoryStore().getItemByID(e.categoryID);
				e.marker = category!.image;
				e.asset = category!.asset;
				return e;
			});
			return items;
		},
		getClaimedItems(state: IVitemItemStoreState): VItem[] {
			const items = state.items.filter((e: VItem) => {
				const prize = prizeStore().getPrizeByID(e.prizeID!);
				return (
					e.itemStatus == "claimed" ||
					e.itemStatus == "redeemed" ||
					(e.itemStatus == "allocated" && prize && prize.isWinningPrize)
				);
			});
			console.log("items");
			console.log(items);
			return items;
		},
		getSelectedItem(state: IVitemItemStoreState): VItem | undefined {
			return state.items.find((e: VItem) => e.id == state.selectedItemID);
		},
	},
	actions: {
		setItems(items: VItem[]): void {
			this.items = items;
		},
		addItems(items: VItem[]): void {
			this.items = items;
		},
		selectItem(id: string) {
			this.selectedItemID = id;
		},
		pushItem(item: VItem): void {
			this.items.push(item);
		},
		updateItem(item: VItem): void {
			const itemsCopy = [...this.items];
			const index = itemsCopy.findIndex((e: VItem) => e.id == item.id);
			if (index != -1) {
				itemsCopy[index] = item;
			}
			this.items = itemsCopy;
		},
		clearItems(): void {
			this.items = [];
		},
		// claimItem(itemID: string): void {
		// 	const item = this.items.find((e: VItem) => e.id == itemID);
		// 	item!.itemStatus = "claimed";
		// },
		allocateItem(itemID: string): void {
			const item = this.items.find((e: VItem) => e.id == itemID);
			item!.itemStatus = "allocated";
		},
		// redeemItem(itemID: string): void {
		// 	const index = this.items.findIndex((e: VItem) => e.id == itemID);
		// 	if (index != -1) {
		// 		this.items.splice(index, 1);
		// 	}
		// },
		async getItems() {
			const campaignID = authStore().campaignID;
			const walletID = authStore().walletID;
			const endpoint = `/campaigns/${campaignID}/getVitemsByWalletId/${walletID}`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.items = response.data;
				} else {
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${campaignID}/campaign-over`);
					}
				}
			} catch (error: any) {
				if (axios.isAxiosError(error)) {
					console.error(`Error ${error.response?.status}: ${error.message}`);
				} else {
					console.error("An unexpected error occurred:", error);
				}
			}
		},
		async dropItems(object: {
			walletID: string;
			segmentID: string;
			poolID: string;
			userLocation: [number, number];
		}) {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/vitems/drop`;
			try {
				const response = await APIClient.post(endpoint, object);
				console.log(response);
				if (response.success) {
					if (response.data.length > 0) {
						this.items = response.data;
					} else {
						navigationStore().emitAction({
							type: "open-popup",
							value: "out-of-items",
						});
					}
				} else {
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${campaignID}/campaign-over`);
					} else if (response.data.status == "MAX-ITEMS-CLAIMED") {
						navigationStore().emitAction({
							type: "open-popup",
							value: "max-items-claimed",
						});
					}
				}
			} catch (error: any) {
				console.error(error);
			}
		},

		async recycleItems(object: { walletID: string; poolID: string }) {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/vitems/recycle`;
			try {
				const response = await APIClient.post(endpoint, object);
				if (response.success) {
					this.items = response.data;
				} else {
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${campaignID}/campaign-over`);
					} else if (response.data.status == "MAX-ITEMS-CLAIMED") {
						navigationStore().emitAction({
							type: "open-popup",
							value: "max-items-claimed",
						});
					}
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		async claimItem(vItemID: string) {
			const campaignID = authStore().campaignID;
			const walletID = authStore().walletID;
			const endpoint = `/campaigns/${campaignID}/vitems/${vItemID}/claim/${walletID}`;
			try {
				const response = await APIClient.get(endpoint);
				console.log(response);
				if (response.success) {
					this.updateItem(response.data);
				} else {
					if (response.data.status == "NOT_FOUND_ERROR") {
						navigationStore().emitAction({
							type: "open-popup",
							value: "item-expired",
						});
					} else if (response.data.status == "MAX-ITEMS-CLAIMED") {
						navigationStore().emitAction({
							type: "open-popup",
							value: "max-items-claimed",
						});
					}
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		async redeemItem(hash: string) {
			const campaignID = authStore().campaignID;
			const splitHash = hash.split("_");
			const itemID = splitHash[0];
			const merchantWalletID = splitHash[1];
			const vItem = this.items.find((e: VItem) => e.id == itemID);
			if (vItem) {
				const redeemObject = {
					vitemID: itemID,
					merchantWalletID: merchantWalletID,
					userID: authStore().userID,
				};
				const endpoint = `/campaigns/${campaignID}/vitems/redeem`;
				try {
					const response = await APIClient.post(endpoint, redeemObject);
					if (response) {
						return response;
					}
				} catch (error: any) {
					console.error(error);
				}
			}
		},
		async redeemMomintNFT(params: {
			merchantWalletID: string;
			itemID: string;
		}) {
			const campaignID = authStore().campaignID;
			const vItem = this.items.find((e: VItem) => e.id == params.itemID);
			if (vItem) {
				const redeemObject = {
					merchantWalletID: params.merchantWalletID,
				};
				const endpoint = `/campaigns/${campaignID}/vitem/${params.itemID}/send-momint`;
				try {
					const response = await APIClient.post(endpoint, redeemObject);
					if (response) {
						return response;
					}
				} catch (error: any) {
					console.error(error.message);
				}
			}
		},
		async setNoPrize() {
			const campaignID = authStore().campaignID;
			const selectedVItemID = this.selectedItemID;
			const endpoint = `/campaigns/${campaignID}/vitems/${selectedVItemID}/noprize`;
			try {
				const response = await APIClient.get(endpoint);
				if (!response.success) {
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${campaignID}/campaign-over`);
					}
				}
				return response;
			} catch (error: any) {
				console.error(error);
			}
		},
		async allocatePrize() {
			const campaignID = authStore().campaignID;
			const selectedVItemID = this.selectedItemID;
			const endpoint = `/campaigns/${campaignID}/vitems/${selectedVItemID}/allocate`;
			try {
				const response = await APIClient.get(endpoint);
				console.log(response);
				if (response.success) {
					this.allocateItem(selectedVItemID!);
					return true;
				} else {
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${campaignID}/campaign-over`);
					}
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		async allocatePrizeByVatId(id: string) {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/vitems/${id}/allocate`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.allocateItem(id);
					return true;
				} else {
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${campaignID}/campaign-over`);
					}
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		async renderGameCodeTemplate(params: {
			templateID: string;
			vItemID: string;
		}) {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/gamecodes/${params.templateID}/vitems/${params.vItemID}/render`;

			try {
				const response = await APIClient.post(endpoint, {});
				if (response.success) {
					return response.data;
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		async allocateGamePrize(params: { vItemID: string }) {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/vitems/${params.vItemID}/allocate/gameprize`;

			try {
				const response = await APIClient.post(endpoint, {});
				if (response.success) {
					return response.data;
				}
			} catch (error: any) {
				console.error(error);
			}
		},
	},
});

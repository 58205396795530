<template>
	<div class="custom-page">
		<ContainerObject v-if="loginPage" :containerObject="loginPage.layout" />
	</div>
</template>

<script lang="ts">
import ContainerObject from "@/components/layout/ContainerObject.vue";
import { CustomPage } from "@/types/customPage";
import { defineComponent } from "vue";
import { customPageStore } from "@/store/pinia_modules/customPageStore";
import { campaignStore } from "@/store/pinia_modules/campaignStore";

export default defineComponent({
	// components: { ContainerObject },
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		loginPage(): CustomPage | undefined {
			console.log(customPageStore().getPageByURL("registration-page"));
			return customPageStore().getPageByURL("registration-page");
		},
		campaign() {
			return campaignStore().getItem;
		},
	},
});
</script>

<style scoped lang="scss">
.custom-page {
	position: relative;
	width: 100%;
	height: 100%;
}
</style>
